import { ReactNode } from 'react';
import Head from 'next/head';
import { BaseLayoutProps } from './types';

type Props = BaseLayoutProps;

export default function BaseLayout({ children, pageTitle }: Props) {
	return (
		<>
			<Head>
				<title>{pageTitle} / Publisher by Yobee</title>
				<link rel="icon" href="/favicon.svg" />
				<meta name="viewport" content="width=1366" />
			</Head>
			{children}
		</>
	);
}
