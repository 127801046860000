export const patternMatch = {
	length: {
		pattern: /^.{8,}$/,
		message: 'Be atleast 8 characters ',
	},
	lowerCase: {
		pattern: /.*[a-z]/,
		message: 'Include a lowercase letter',
	},
	upperCase: {
		pattern: /.*[A-Z]/,
		message: 'Include an uppercase letter',
	},
	number: {
		pattern: /.*[0-9]/,
		message: 'Include a number',
	},
	character: {
		pattern: /.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`]/,
		message: 'Include a special character',
	},
};