import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { Input } from '@chakra-ui/input';
import { Button, Stack, VStack } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { Text } from '@chakra-ui/layout';
import { CognitoUser } from 'amazon-cognito-identity-js';
import PasswordPatternCheck from './passwordPatternCheck';
import { loginErrorTypes } from './errors';

type Props = {
	user: CognitoUser,
	requiredAttributes: {
		email: string
	},
	isOpen: boolean,
	onClose: () => void,
	mode?: string,
	onSuccess?: () => void,
	onFail?: () => void
}

const NewUserPassword = ({ user, requiredAttributes, isOpen, onClose, mode, onSuccess, onFail }: Props) => {

	const newRef = useRef(null);
	const verifyRef = useRef(null);
	const submitRef = useRef(null);

	const [password, setPassword] = useState({
		new: '',
		verify: '',
	});

	const [flags, setFlags] = useState({
		loading: false,
		error: false,
		errorType: loginErrorTypes.NULL,
	});

	const [passwordTest, setPasswordTest] = useState(false);

	const handlePassword = (field) => (event) => {
		setFlags(prevState => ({
			...prevState,
			loading: false,
			error: false,
			errorType: loginErrorTypes.NULL,
		}));
		setPassword(prev => ({
			...prev,
			[field]: event.target.value,
		}));
	};

	const handleEnter = (field) => (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			if (field === 'new') {
				verifyRef.current.focus();
			} else {
				submitRef.current.focus();
				submitRef.current.click();
			}
		}
	};

	const handleSuccess = (data) => {
		onClose();
		onSuccess?.();
	};

	const handleFail = (data) => {
		setFlags(prevState => ({
			...prevState,
			loading: false,
			error: true,
			errorType: data.code,
		}));
		newRef.current.focus();
		onFail?.();
	};

	const handleSubmit = () => {
		setFlags(prevState => ({
			...prevState,
			loading: true,
			error: false,
			errorType: loginErrorTypes.NULL,
		}));

		if (password.new === '' || password.verify === '') {
			return handleFail({ code: loginErrorTypes.EMPTY_PASSWORD });
		}

		if (password.new !== password.verify) {
			return handleFail({ code: loginErrorTypes.PASSWORD_MISMATCH });
		}

		if (!passwordTest) {
			return handleFail({ code: loginErrorTypes.PASSWORD_PATTERN_MISMATCH });
		}

		user.completeNewPasswordChallenge(password.new, requiredAttributes, {
			onSuccess: handleSuccess,
			onFailure: handleFail,
		});
	};


	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered={true} initialFocusRef={newRef}>
			<ModalOverlay />
			<ModalContent w={'360px'} bg={'white'} pt={4} pb={8}>
				<ModalCloseButton />
				<ModalHeader>
					Set New Password
				</ModalHeader>
				<ModalBody>
					<Stack direction={'column'} spacing={8} w={'100%'}>
						<VStack spacing={4} w={'100%'}>
							<Input
								ref={newRef}
								disabled={flags.loading}
								size={'lg'}
								type={'password'}
								placeholder={'Enter New Password'}
								value={password.new}
								onChange={handlePassword('new')}
								onKeyPress={handleEnter('new')}
							/>
							<Input
								ref={verifyRef}
								disabled={flags.loading}
								size={'lg'}
								type={'password'}
								placeholder={'Re-enter New Password'}
								value={password.verify}
								onChange={handlePassword('verify')}
								onKeyPress={handleEnter('verify')}
							/>
							<Button
								ref={submitRef}
								size={'lg'}
								colorScheme={'purple'}
								w={'100%'}
								isLoading={flags.loading}
								loadingText={'Setting New Password'}
								onClick={handleSubmit}
							>
								Set New Password
							</Button>
							<Text color={'red.500'} display={flags.error ? 'block' : 'none'}>
								{flags.errorType.message}
							</Text>
						</VStack>
						<PasswordPatternCheck password={password.new} onChange={setPasswordTest} />
					</Stack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default NewUserPassword;