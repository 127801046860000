export const loginErrorTypes = {
	NULL: {
		code: 'Null',
		message: '',
	},
	UNKNOWN: {
		code: 'Unknown',
		message: 'Something went wrong',
	},
	TOO_MANY_ATTEMPT: {
		code: 'TooManyAttempts',
		message: 'Too many attempts, please retry after sometime',
	},
	EMPTY_EMAIL: {
		code: 'EmptyEmail',
		message: 'Email cannot be empty',
	},
	EMPTY_PASSWORD: {
		code: 'EmptyPassword',
		message: 'Password cannot be empty',
	},
	EMPTY_CODE: {
		code: 'EmptyCode',
		message: 'Both code and password are compulsory',
	},
	EMPTY_EMAIL_PASSWORD: {
		code: 'EmptyUserPassword',
		message: 'Both email and password are compulsory',
	},
	INCORRECT_CODE: {
		code: 'IncorrectCode',
		message: 'Incorrect code',
	},
	USER_NOT_EXIST: {
		code: 'UserNotExist',
		message: 'User does not exist',
	},
	USER_NOT_AUTHORISED_LOGIN: {
		code: 'UserNotAuthorizedLogin',
		message: 'Invalid email or password',
	},
	USER_NOT_AUTHORIZED_FORGOT: {
		code: 'UserNotAuthorizedForgot',
		message: 'Login with temporary password sent earlier',
	},
	PASSWORD_MISMATCH: {
		code: 'PasswordMismatch',
		message: 'Passwords do not match',
	},
	PASSWORD_PATTERN_MISMATCH: {
		code: 'PasswordPatternMismatch',
		message: 'Password is not strong',
	}
};