import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { Button, Stack, useToast } from '@chakra-ui/react';
import { Input } from '@chakra-ui/input';
import { useRef, useState } from 'react';
import { cognitoUser } from '../../../utils/cognito';
import { Text } from '@chakra-ui/layout';
import PasswordPatternCheck from './passwordPatternCheck';
import { loginErrorTypes } from './errors';

type Props = {
	email: string,
	isOpen: boolean,
	onClose: () => void,
	onSuccess?: (email) => void,
	onFail?: () => void,
	initialStage?: 'email' | 'code'
}

const ForgotPassword = ({ email, isOpen, onClose, onSuccess, onFail, initialStage = 'email' }: Props) => {

	const emailRef = useRef(null);
	const codeRef = useRef(null);
	const passwordRef = useRef(null);
	const submitRef = useRef(null);

	const toastID = 'success';
	const toast = useToast();

	const [cred, setCred] = useState({
		email: email,
		code: '',
		password: '',
	});

	const [flags, setFlags] = useState({
		stage: initialStage,
		loading: false,
		error: false,
		errorType: loginErrorTypes.NULL,
	});

	const [passwordTest, setPasswordTest] = useState(false);

	const handleCred = (field) => (event) => {
		setCred(prevState => ({
			...prevState,
			[field]: event.target.value,
		}));
	};

	const handleEnter = (field) => (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			if (field === 'email') {
				submitRef.current.focus();
				submitRef.current.click();
			} else if (field === 'code') {
				passwordRef.current.focus();
			} else if (field === 'password') {
				submitRef.current.focus();
				submitRef.current.click();
			}
		}
	};

	// let user;

	const handleEmailSubmitSuccess = (data) => {
		setFlags(prevState => ({
			...prevState,
			stage: 'code',
			loading: false,
			error: false,
			errorType: loginErrorTypes.NULL,
		}));
		codeRef.current.focus();
	};

	const handleEmailSubmitFail = (data) => {
		setFlags(prevState => ({
			...prevState,
			loading: false,
			error: true,
			errorType: data
				? data.code === 'LimitExceededException' ? loginErrorTypes.TOO_MANY_ATTEMPT
					: data.code === 'UserNotFoundException' || data.code === 'ResourceNotFoundException' ? loginErrorTypes.USER_NOT_EXIST
						: data.code === 'NotAuthorizedException' ? loginErrorTypes.USER_NOT_AUTHORIZED_FORGOT
							: data.code : loginErrorTypes.UNKNOWN,
		}));
		emailRef.current.focus();
	};

	const handleEmailSubmit = () => {

		setFlags(prevState => ({
			...prevState,
			loading: true,
			error: false,
			errorType: loginErrorTypes.NULL,
		}));

		if (cred.email === '') {
			return handleEmailSubmitFail({ code: loginErrorTypes.EMPTY_EMAIL });
		}

		const user = cognitoUser(cred.email);
		user.forgotPassword({
			onSuccess: handleEmailSubmitSuccess,
			onFailure: handleEmailSubmitFail,
			// inputVerificationCode: handleCodeSubmit,
		});
	};

	const handleCodeSubmitSuccess = (data) => {
		setFlags(prevState => ({
			...prevState,
			loading: false,
			error: false,
			errorType: loginErrorTypes.NULL,
		}));

		if (!toast.isActive(toastID)) {
			toast({
				id: toastID,
				title: 'Password Successfully Set',
				status: 'success',
			});
		}

		onSuccess?.(cred.email);
	};

	const handleCodeSubmitFail = (data) => {
		setFlags(prevState => ({
			...prevState,
			loading: false,
			error: true,
			errorType: data ? data.code === 'CodeMismatchException' ? loginErrorTypes.INCORRECT_CODE
				: data.code === 'LimitExceededException' ? loginErrorTypes.TOO_MANY_ATTEMPT
					: data.code : loginErrorTypes.UNKNOWN,
		}));
		codeRef.current.focus();
	};

	const handleCodeSubmit = () => {

		setFlags(prevState => ({
			...prevState,
			loading: true,
			error: false,
			errorType: loginErrorTypes.NULL,
		}));

		if (cred.code === '' || cred.password === '') {
			return handleCodeSubmitFail({ code: loginErrorTypes.EMPTY_CODE });
		}

		if (!passwordTest) {
			return handleCodeSubmitFail({ code: loginErrorTypes.PASSWORD_PATTERN_MISMATCH });
		}

		const user = cognitoUser(cred.email);
		user.confirmPassword(cred.code, cred.password, {
			onSuccess: handleCodeSubmitSuccess,
			onFailure: handleCodeSubmitFail,
		});
	};


	return (
		<Modal initialFocusRef={emailRef} isOpen={isOpen} onClose={onClose} isCentered={true}>
			<ModalOverlay />
			<ModalContent w={'360px'} bg={'white'} pt={4} pb={8}>
				<ModalCloseButton />
				<ModalHeader>
					Forgot Password
				</ModalHeader>
				<ModalBody>
					<Stack direction={'column'} w={'100%'} spacing={4}>
						{flags.stage === 'email' &&
						<Stack direction={'column'} w={'100%'} spacing={4}>
							<Input
								ref={emailRef}
								disabled={flags.loading}
								size={'lg'}
								type={'email'}
								placeholder={'Enter Email ID'}
								value={cred.email}
								onChange={handleCred('email')}
								onKeyPress={handleEnter('email')}
							/>
							<Button
								ref={submitRef}
								size={'lg'}
								colorScheme={'purple'}
								w={'100%'}
								isLoading={flags.loading}
								loadingText={'Sending Reset Code'}
								onClick={handleEmailSubmit}
							>
								Send Reset Code
							</Button>
							<Text textAlign={'center'} fontSize={'sm'} fontWeight={'semibold'} color={'red.600'}
								  display={flags.error ? 'block' : 'none'}>
								{flags.errorType.message}
							</Text>
						</Stack>
						}
						{flags.stage === 'code' &&
						<Stack direction={'column'} w={'100%'} spacing={4}>
							<Text justifyContent={'start'} fontSize={'sm'}>
								Reset code sent to <Text as={'em'}>{cred.email}</Text>
							</Text>
							<Input
								ref={codeRef}
								disabled={flags.loading}
								size={'lg'}
								type={'number'}
								placeholder={'Enter Reset Code'}
								value={cred.code}
								onChange={handleCred('code')}
								onKeyPress={handleEnter('code')}
							/>
							<Input
								ref={passwordRef}
								disabled={flags.loading}
								size={'lg'}
								type={'password'}
								placeholder={'Enter New Password'}
								value={cred.password}
								onChange={handleCred('password')}
								onKeyPress={handleEnter('password')}
							/>
							<Button
								ref={submitRef}
								size={'lg'}
								colorScheme={'purple'}
								w={'100%'}
								isLoading={flags.loading}
								loadingText={'Setting Password'}
								onClick={handleCodeSubmit}
							>
								Set Password
							</Button>
							<Text textAlign={'center'} fontSize={'sm'} fontWeight={'semibold'} color={'red.600'}
								  display={flags.error ? 'block' : 'none'}>
								{flags.errorType.message}
							</Text>
							<PasswordPatternCheck password={cred.password} onChange={setPasswordTest} />
						</Stack>
						}
					</Stack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default ForgotPassword;