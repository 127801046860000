import { Text } from '@chakra-ui/layout';
import { HStack, List, ListIcon, ListItem, Stack } from '@chakra-ui/react';
import { patternMatch } from './patterns';
import { FaCheckCircle } from 'react-icons/fa';
import { useEffect, useState } from 'react';

type Props = {
	password: string,
	onChange?: (status) => void
}

const PasswordPatternCheck = ({ password, onChange }: Props) => {

	const [passwordTest, setPasswordTest] = useState(Object.assign({},
		...Object.keys(patternMatch).map(item => ({ [item]: false })),
	));

	useEffect(() => {
		for (let key of Object.keys(patternMatch)) {
			setPasswordTest(prev => ({
				...prev,
				[key]: patternMatch[key]['pattern'].test(password),
			}));
		}
	}, [password]);

	onChange?.(Object.values(passwordTest).every(Boolean));

	return (
		<Stack direction={'column'} spacing={6}>
			<Text color={'black'}>
				Your password should
			</Text>
			<List spacing={4}>
				{Object.keys(patternMatch).map(key => (
					<ListItem key={key}>
						<HStack>
							<ListIcon as={FaCheckCircle}
									  color={passwordTest[key] ? 'green.500' : 'gray.500'}
									  transition={'300ms'} />
							<Text color={passwordTest[key] ? 'black' : 'gray.500'} transition={'150ms'}>
								{patternMatch[key]['message']}
							</Text>
						</HStack>
					</ListItem>
				))}
			</List>
		</Stack>
	);


};

export default PasswordPatternCheck;